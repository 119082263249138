import CompassIcon from '@/components/icons/compass-icon';
import FileIcon from '@/components/icons/file-icon';
import UserFollowingIcon from '@/components/icons/user-following-icon';
import LayersIcon from '@/components/icons/layers-icon';
import MainLayout from '@/components/main-layout';
import React from 'react';
import MapIcon from '@/components/icons/map-icon';
import SpeechIcon from '@/components/icons/speech-icon';
import HomeIcon from '@/components/icons/home-icon';
import TaskIcon from '@/components/icons/task-icon';
import FenceIcon from '@/components/icons/fence-icon';
import SofaIcon from '@/components/icons/sofa-icon';
import SEO from '@/components/seo';

type ServiceProps = {
  name: string;
  description: string;
  icon: React.ReactNode;
};
const Service: React.FC<ServiceProps> = ({ name, description, icon }) => (
  <div className="lg:text-center pb-16 lg:p-10">
    <div className="flex lg:justify-center">{icon}</div>
    <h2 className="text-xl font-semibold my-4">{name}</h2>
    <p>{description}</p>
  </div>
);

const Sluzby: React.FC = () => {
  const services = [
    {
      name: 'Predprojektová činnosť',
      description:
        'Celkové poradenstvo pri výbere adekvátneho pozemku so zohľadnením na vhodnú orientáciu svetových strán. Prekonzultovanie vhodnosti pozemku na účely vami zvoleného druhu stavby. Toto prehodnotenie v sebe zahŕňa nutnosť súladu s územným plánom, dostupnosť inžinierskych sietí, dostupnosti verejných rozvodov a iné.',
      icon: <TaskIcon />,
    },
    {
      name: 'Architektonická štúdia',
      description:
        'Osobná prehliadka vybraného pozemku a následne vytvorenie návrhu stavby pomocou počítačového 3D modelu. Tomuto vypracovaniu samozrejme predchádza vyjadrenie investora o jeho individuálnych požiadavkách: poloha, dispozičné riešenie, veľkosť zastavanej plochy, orientácia ku svetovým stranám.',
      icon: <LayersIcon />,
    },
    {
      name: 'Dokumentácia pre územne konanie',
      description:
        'Spracovanie komplexnej projektovej dokumentácie potrebnej pre vydanie územného rozhodnutia.',
      icon: <CompassIcon />,
    },
    {
      name: 'Dokumentácia pre stavebné povolenie',
      description:
        'Spracovanie projektovej dokumentácie vrátane jednotlivých profesií a prípojok potrebných pre udelenie stavebného povolenia.',
      icon: <FileIcon />,
    },
    {
      name: 'Dokumentácia pre ohlásenie stavby',
      description: `Spracovanie projektovej dokumentácie vrátane jednotlivých profesií a prípojok potrebných pre realizáciu stavby.
      Realizačný projekt sa vypracúva už k samotnej realizácii stavby a jeho podrobnosť má umožňovať realizovať stavbu. Oficiálnym dokumentom zostáva dokumentácia pre stavebné povolenie DSP schválená príslušným stavebným úradom (opatrená pečiatkou).`,
      icon: <MapIcon />,
    },
    {
      name: 'Interiérový design',
      description:
        'Zameranie sa na rozmiestnenie jednotlivých miestností na základe požiadaviek investora, prípade po konzultácii s projektantom. Vhodné umiestnenie jednotlivých elektrických zariadení, ale aj zvyšku potrebného nábytku či iného vybavenia jednotlivých obývacích miestností.',
      icon: <SofaIcon />,
    },
    {
      name: 'Výrobno-technická dokumentácia',
      description:
        'Spracovanie výrobno-technickej dokumentácie, dielenskej dokumentácie. Výrobno-technická dokumentácia oceľových konštrukcií (zábradlia, oplotenie, prístrešky, markízy), sklenených zábradlí, atď.',
      icon: <FenceIcon />,
    },
    {
      name: 'Vypracovanie 3D vizualizácie',
      description:
        'Vyhotovenie počítačových 3D modelov a následných vizualizácií, návrhy objektov a ich zmien, farebné a materiálové návrhy fasád.',
      icon: <HomeIcon size={42} />,
    },
    {
      name: 'Poradenstvo',
      description:
        'Poskytujeme aj odbornú pomoc pri výbere primeraných materiálov pre daný druh výstavby, optimalizáciu tepelno-technických vlastností obalových konštrukcií, riešenie konštrukčných detailov, autorský dozor, zabezpečenie výberového konania na dodávateľa stavby a podobne.',
      icon: <SpeechIcon />,
    },
    {
      name: 'Autorský dozor',
      description:
        'Kontrola výstavby, jej postupu a riešenie detailov v súlade s projektovou dokumentáciou a architektonickým zámerom.',
      icon: <UserFollowingIcon />,
    },
  ];

  return (
    <>
      <SEO
        title="Služby"
        description="BIM house je spojením kvalitných služieb a profesionálnej komunikácie po celú dobu spolupráce od prvotnej konzultácie, až po odovzdanie finálneho projektu podľa želaní."
        image="/service.jpg"
      />
      <MainLayout>
        <div className="container m-auto md:px-20 px-4 py-20 text-white">
          <div
            className="mb-10 m-auto bg-white"
            style={{
              width: '80px',
              height: '10px',
            }}
          />
          <p className="mb-2 text-center">
            BIM house je spojením kvalitných služieb a profesionálnej
            komunikácie po celú dobu spolupráce od prvotnej konzultácie, až po
            odovzdanie finálneho projektu podľa želaní. Venujeme sa tvorbe
            rozličných druhov stavieb, ktoré majú spoločnú modernú architektúru,
            minimalizmus a profesionálne prevedenie za pomoci najnovších
            programov.
          </p>
          <p className="text-center">
            Naša práca nekončí po odovzdaní projektu, ale až po vyjadrení 100%
            spokojnosti zo strany nášho zákazníka.
          </p>
          <div
            className="mt-10 m-auto bg-white"
            style={{
              width: '80px',
              height: '10px',
            }}
          />
          <div className="flex justify-center flex-wrap mt-14">
            {services.map((service) => (
              <div className="w-full lg:w-1/3">
                <Service {...service} key={service.name} />
              </div>
            ))}
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default Sluzby;
