import React from 'react';

const UserFollowingIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    focusable="false"
    width="42"
    height="42"
    style={{ transform: 'rotate(360deg)' }}
    viewBox="0 0 1024 1024"
  >
    <path d="M64 960v-65q0-25 26-37l281-133q33-15 37-50t-23-58q-46-36-72-107t-26-126V224q0-16 9.5-35t25-36.5T359 119t46.5-28t52-19.5T511 64q52 0 105.5 26t86 63t32.5 71v160q0 57-24 127t-70 106q-9 7-14.5 16.5t-8 20T617 675q2 17 12 30.5t25 20.5l162 77l38-54l-173-81q60-48 89-130.5T799 384V224q0-52-42-104T647.5 34t-136-34T375 34t-110 86t-42 104v160q0 66 30.5 150.5T344 667L63 800q-1 0-3 1.5t-9 5t-13 7.5t-13.5 10T12 836t-8.5 13T0 864v96q0 26 18.5 45t44.5 19h640l-52-64H64zm948-236q-4-4-9-5.5t-9.5-1.5t-9.5 1t-9 4t-8 7L830 938l-85-85q-9-10-22-10t-23 10q-4 3-6 7.5t-3 9.5t0 10t3 9.5t6 8.5l113 113q10 9 23 9t23-9q4-4 6-10l153-233q8-10 6.5-23t-12.5-21z" />
  </svg>
);

export default UserFollowingIcon;
