import React from 'react';

const SofaIcon: React.FC = () => (
  <svg
    height="42"
    viewBox="0 0 74 74"
    width="42"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m12.235 45.212a1 1 0 0 1 -.394-1.919c9.213-3.953 18.168-3.948 26.616.014a1 1 0 0 1 -.85 1.81c-7.907-3.709-16.311-3.7-24.978.014a1 1 0 0 1 -.394.081z" />
    <path d="m38.032 45.212a1 1 0 0 1 -.394-1.919c9.214-3.953 18.169-3.948 26.616.014a1 1 0 0 1 -.85 1.81c-7.907-3.708-16.311-3.7-24.978.014a1 1 0 0 1 -.394.081z" />
    <path d="m69.067 32.438a1 1 0 0 1 -1-1v-6.626a4.688 4.688 0 0 0 -4.682-4.682h-50.706a4.688 4.688 0 0 0 -4.679 4.682v6.626a1 1 0 0 1 -2 0v-6.626a6.69 6.69 0 0 1 6.683-6.682h50.702a6.69 6.69 0 0 1 6.682 6.682v6.626a1 1 0 0 1 -1 1z" />
    <path d="m17.722 62.046h-5.222a1 1 0 0 1 -1-1v-4.306a1 1 0 0 1 2 0v3.306h3.7l2.656-3.872a1 1 0 0 1 1.644 1.131l-2.954 4.306a1 1 0 0 1 -.824.435z" />
    <path d="m63.562 62.046h-5.22a1 1 0 0 1 -.825-.435l-2.954-4.306a1 1 0 0 1 1.65-1.131l2.656 3.872h3.693v-3.306a1 1 0 1 1 2 0v4.306a1 1 0 0 1 -1 1z" />
    <path d="m19.515 42.7a1 1 0 0 1 -.464-.115l-2.851-1.485a2.5 2.5 0 0 1 -1.062-3.374l2.6-4.963a2.515 2.515 0 0 1 3.362-1.072l4.97 2.6a2.509 2.509 0 0 1 1.062 3.381l-2.12 4.048a1 1 0 0 1 -1.772-.927l2.121-4.049a.5.5 0 0 0 .033-.381.508.508 0 0 0 -.251-.3l-4.964-2.595a.5.5 0 0 0 -.674.218l-2.6 4.963a.5.5 0 0 0 .212.672l2.857 1.5a1 1 0 0 1 -.465 1.886z" />
    <path d="m57.524 42.726a1 1 0 0 1 -.468-1.885l.127-.067a.64.64 0 0 0 .277-.861l-2.827-5.408a.641.641 0 0 0 -.379-.317.627.627 0 0 0 -.484.041l-5.4 2.83a.648.648 0 0 0 -.319.381.618.618 0 0 0 .042.472l1.518 2.908a1 1 0 1 1 -1.773.926l-1.516-2.9a2.6 2.6 0 0 1 -.18-2 2.625 2.625 0 0 1 1.3-1.559l5.4-2.829a2.64 2.64 0 0 1 3.563 1.12l2.829 5.411a2.645 2.645 0 0 1 -1.12 3.555l-.124.065a.991.991 0 0 1 -.466.117z" />
    <path d="m12.8 57.74a18.4 18.4 0 0 1 -3.03-.462 9.031 9.031 0 0 1 -6.741-8.865v-16.713a1 1 0 0 1 .724-.961l.3-.085a7.4 7.4 0 0 1 9.11 5.135 1.021 1.021 0 0 1 .037.269l.008 9.758a1.721 1.721 0 0 0 1.719 1.717h46.21a1.721 1.721 0 0 0 1.718-1.717l.009-9.758a.981.981 0 0 1 .037-.269 7.4 7.4 0 0 1 9.107-5.136l.3.086a1 1 0 0 1 .725.961v16.713a9.028 9.028 0 0 1 -6.733 8.863 18.307 18.307 0 0 1 -2.959.461zm-7.768-25.268v15.941a7.034 7.034 0 0 0 5.22 6.923 16.407 16.407 0 0 0 2.626.407h50.383a16.4 16.4 0 0 0 2.556-.406 7.032 7.032 0 0 0 5.215-6.921v-15.944a5.508 5.508 0 0 0 -1.021-.094 5.413 5.413 0 0 0 -5.147 3.822l-.009 9.614a3.723 3.723 0 0 1 -3.718 3.716h-46.21a3.724 3.724 0 0 1 -3.719-3.716l-.008-9.614a5.413 5.413 0 0 0 -5.147-3.821 5.508 5.508 0 0 0 -1.021.093z" />
  </svg>
);

export default SofaIcon;
